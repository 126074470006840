import { AuthenticationDetails } from "amazon-cognito-identity-js";
import * as actionNames from "../actions/actionNames";

export default (
  state = {
    failedForgotPassword: false,
    msg: "",
    submitted: false,
    submittedWithCode: false,
  },
  action
) => {
  switch (action.type) {
    case actionNames.HANDLE_FORGOT_PASSWORD_FAIL:
      return {
        failedForgotPassword: true,
        msg: action.payload.err.message,
        submitted: action.payload.withCode,
        submittedWithCode: false,
      };
    case actionNames.HANDLE_FORGOT_PASSWORD_SUCCESS:
      let msg =
        "You should receive a code at the email entered above. Once received," +
        " enter the code above and re-submit this form. Didn't receive a " +
        "code? Check your spam folder and the usual. It will be an emil from " +
        'noreply@verificationemail.com with the subject "Your verification ' +
        'code". You can also try registering, or resending the code. If ' +
        "nothing works just ask Daniel for help.";
      if (action.payload.withCode)
        msg =
          "Password changed successfully! You can now log in with your new password";
      return {
        failedForgotPassword: false,
        msg: msg,
        submitted: true,
        submittedWithCode: action.payload.withCode,
      };
    case actionNames.CLEAR_FORGOT_PASSWORD_RESULT:
      return {
        failedForgotPassword: false,
        msg: "",
        submitted: action.payload.submitted,
        submittedWithCode: action.payload.submittedWithCode,
      };
    default:
      return state;
  }
};
