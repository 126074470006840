import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faThumbsDown,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {
  getNewPlayers,
  loadNewPlayers,
  togglePlayerApproval,
  clearLoginFailureMessage,
} from "../../actions";
import "./NewUserRequests.css";

class Requests extends Component {
  componentDidMount() {
    if (
      this.props.session &&
      this.props.newPlayers.newPlayerRequests === undefined
    )
      this.props.getNewPlayers(this.props.session);
  }

  componentDidUpdate() {
    if (
      this.props.session &&
      this.props.newPlayers.newPlayerRequests === undefined
    )
      this.props.getNewPlayers(this.props.session);
  }

  clearResultMsg(props) {
    props.clearLoginFailureMessage();
    props.loadNewPlayers();
    props.getNewPlayers(props.session);
  }

  renderMessage(props) {
    const closeMsgBtn = (
      <div
        className="btn close-message"
        onClick={() => {
          this.clearResultMsg(props);
        }}
      >
        <span>X</span>
      </div>
    );
    let messageClass = props.approvalResult.failedLogin ? "danger" : "success";
    return (
      <div className={"msg alert alert-" + messageClass}>
        <div className="header">{props.approvalResult.msg}</div>
        {closeMsgBtn}
      </div>
    );
  }

  renderRequest(email) {
    // const color = active ? "#6ead43" : "#d43030"; // green : red
    let progressClass =
      this.props.newPlayers.approvalInProgress ||
      this.props.approvalResult.msg !== ""
        ? " disabled"
        : " enabled";
    let resultMsg =
      this.props.approvalResult.msg !== ""
        ? this.renderMessage(this.props)
        : "";
    return (
      <div
        className={"request" + progressClass}
        key={email.replace("@", "").replace(".", "")}
      >
        <FontAwesomeIcon
          icon={faThumbsUp}
          className={"approve" + progressClass}
          onClick={() =>
            this.props.togglePlayerApproval(
              this.props.session,
              email,
              "approve"
            )
          }
        />
        <FontAwesomeIcon
          icon={faThumbsDown}
          className={"reject" + progressClass}
          onClick={() =>
            this.props.togglePlayerApproval(this.props.session, email, "reject")
          }
        />
        {email}
        {resultMsg}
      </div>
    );
  }

  renderRequests() {
    if (this.props.newPlayers.newPlayerRequests === undefined) return [];
    let requestDivs = [];
    for (let i in this.props.newPlayers.newPlayerRequests) {
      requestDivs.push(
        this.renderRequest(this.props.newPlayers.newPlayerRequests[i])
      );
    }
    if (requestDivs.length === 0) return <div className="col-12"></div>;
    return requestDivs;
  }

  render() {
    if (this.props.newPlayers.newPlayerRequests === undefined)
      return (
        <div className="col-12">
          <div className="player-requests col-12 col-md-5">
            <FontAwesomeIcon icon={faSpinner} pulse />
          </div>
        </div>
      );

    return (
      <div className="requests">
        <h2>Requests</h2>
        <div className="requests-list">{this.renderRequests()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newPlayers: state.newPlayers,
    requests: state.requests,
    session: state.session.session,
    approvalResult: state.loginResult,
  };
};

export default connect(mapStateToProps, {
  getNewPlayers,
  loadNewPlayers,
  togglePlayerApproval,
  clearLoginFailureMessage,
})(Requests);
