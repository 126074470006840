import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Redirect } from "react-router-dom";
import { Form } from "../Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  completeRedirectToChangePassword,
  handleFailedFormSubmit,
  initiateFormSubmit,
  completeFormSubmit,
  markPasswordsDoNotMatch,
  unmarkPasswordsDoNotMatch,
  markEmailsDoNotMatch,
  unmarkEmailsDoNotMatch,
  clearLoginFailureMessage,
  completeRedirectToRegistrationForm,
  registerNewUser,
  clearRegistrationResult,
} from "../../actions";
import "./Register.css";

class Register extends Form {
  nevermind({ completeRedirectToRegistrationForm }) {
    completeRedirectToRegistrationForm();
  }

  validateForm(
    { email, emailconfirm, password, passwordconfirm },
    {
      markPasswordsDoNotMatch,
      unmarkPasswordsDoNotMatch,
      markEmailsDoNotMatch,
      unmarkEmailsDoNotMatch,
    }
  ) {
    let result = true;

    // validate emails
    let re = new RegExp("^[\\w\\.\\d\\-_\\+]+@[\\w\\d\\-_\\+]+\\.[\\w]+$");
    if (!email || !emailconfirm) {
      result = false;
    } else if (email !== emailconfirm || !re.test(email)) {
      markEmailsDoNotMatch();
      result = false;
    } else {
      unmarkEmailsDoNotMatch();
    }

    // validate passwords (cognito handles password complexity checks)
    if (password !== passwordconfirm) {
      markPasswordsDoNotMatch();
      result = false;
    } else {
      unmarkPasswordsDoNotMatch();
    }

    return result;
  }

  handleFailedSubmit({ handleFailedFormSubmit, completeFormSubmit }) {
    handleFailedFormSubmit();
    completeFormSubmit();
  }

  handleOnSubmit(formValues, props) {
    if (this.validateForm(formValues, props)) {
      // indicate form submit is in progress
      props.initiateFormSubmit();
      props.registerNewUser(formValues);
    } else {
      this.handleFailedSubmit(this.props);
    }
  }

  clearMessage = (props) => {
    // if (props.registrationResult.submitted && !props.registrationResult.failedRegistration)
    //   props.completeFormSubmit()

    // clear either password mismatch message or login failure message
    props.unmarkEmailsDoNotMatch();
    props.unmarkPasswordsDoNotMatch();
    props.clearLoginFailureMessage();
    props.clearRegistrationResult();
  };

  renderMessage(props) {
    props.completeFormSubmit();
    const closeMsgBtn = (
      <div
        className="btn close-message"
        onClick={() => {
          this.clearMessage(props);
        }}
      >
        x
      </div>
    );

    // handle registration results
    if (props.registrationResult.submitted === true) {
      let alertType = props.registrationResult.failedRegistration
        ? "danger"
        : "success";

      return (
        <div className={"msg alert alert-" + alertType}>
          <div className="header">{props.registrationResult.msg}</div>
          {closeMsgBtn}
        </div>
      );
    }

    // handle submit failures
    else if (props.emailsDoNotMatch || props.passwordsDoNotMatch) {
      props.completeFormSubmit();
      let msg = props.emailsDoNotMatch
        ? "Emails don't match or email is invalid"
        : "Passwords don't match";
      return (
        <div className="msg alert alert-danger">
          <div className="header">{msg}</div>
          {closeMsgBtn}
        </div>
      );
    } else if (
      props.failedLoginMsg !== "" &&
      props.failedLoginMsg !== undefined
    ) {
      props.completeFormSubmit();
      const sanitizedMsg = props.failedLoginMsg
        .replace("Password does not conform to policy: ", "")
        .replace("Incorrect username or password.", "Incorrect password");
      return (
        <div className="msg alert alert-danger">
          <div className="header">{sanitizedMsg}</div>
          {closeMsgBtn}
        </div>
      );
    } else {
      return "";
    }
  }

  renderButtonMessage({ formSubmitInProgress }) {
    if (formSubmitInProgress) {
      return (
        <span>
          <FontAwesomeIcon icon={faSpinner} spin />
        </span>
      );
    } else {
      return <span>Submit</span>;
    }
  }

  render() {
    let returnMsg =
      this.props.registrationResult.failedRegistration === false &&
      this.props.registrationResult.submitted === true
        ? "Return to login"
        : "Nevermind";

    // handle page display or redirect
    if (!this.props.goToRegistrationForm) {
      // If refreshing page or opening new session on this page, redirect home
      // if not logged in
      return (
        <div className="backdrop">
          <div className="overlay">
            <div>
              <Redirect to="/" />
            </div>
          </div>
        </div>
      );
    } else {
      // DEFAULT: Display form
      return (
        <div className="backdrop">
          <div className="blur">
            <div className="overlay"></div>
          </div>
          <div className="overlay">
            <div className={"Register " + this.containerClass}>
              <h1>Request access to Happy Landings</h1>
              <form
                className={this.formClass}
                onSubmit={this.props.handleSubmit((formValues) => {
                  this.handleOnSubmit(formValues, this.props);
                })}
              >
                <Field
                  name="email"
                  component={this.renderInput}
                  label="Email"
                  type="text"
                  placeholder="myemail@domain.com"
                />
                <Field
                  name="emailconfirm"
                  component={this.renderInput}
                  label="Confirm Email"
                  type="text"
                  placeholder="re-type email..."
                />
                <Field
                  name="password"
                  component={this.renderInput}
                  label="Password"
                  type="password"
                  placeholder="enter password"
                />
                <Field
                  name="passwordconfirm"
                  component={this.renderInput}
                  label="Password (Confirm)"
                  type="password"
                  placeholder="retype password"
                />
                {this.renderMessage(this.props)}
                <button
                  className={this.btnClass}
                  disabled={
                    this.props.formSubmitInProgress ||
                    this.props.registrationResult.submitted
                  }
                >
                  {this.renderButtonMessage(this.props)}
                </button>
              </form>
              <button
                className="nevermind"
                disabled={this.props.formSubmitInProgress}
                onClick={() => this.nevermind(this.props)}
              >
                <span>{returnMsg}</span>
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    formSubmitInProgress: state.formSubmitInProgress,
    failedFormSubmit: state.failedFormSubmit,
    requiresLogin: state.session.requiresLogin,
    email: state.session.email,
    session: state.session.session,
    failedLogin: state.loginResult.failedLogin,
    failedLoginMsg: state.loginResult.msg,
    newPasswordChallenge: state.newPasswordChallenge,
    redirectToChangePassword: state.redirectToChangePassword,
    passwordsDoNotMatch: state.passwordsDoNotMatch,
    emailsDoNotMatch: state.emailsDoNotMatch,
    goToRegistrationForm: state.goToRegistrationForm,
    registrationResult: state.registrationResult,
  };
};

export default connect(mapStateToProps, {
  completeRedirectToChangePassword,
  handleFailedFormSubmit,
  initiateFormSubmit,
  completeFormSubmit,
  markPasswordsDoNotMatch,
  unmarkPasswordsDoNotMatch,
  clearLoginFailureMessage,
  completeRedirectToRegistrationForm,
  markEmailsDoNotMatch,
  unmarkEmailsDoNotMatch,
  registerNewUser,
  clearRegistrationResult,
})(reduxForm({ form: "login", touchOnBlur: false })(Register));
