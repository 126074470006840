import React, { Component } from "react";
import { connect } from "react-redux";
import PlayerStats from "../PlayerStats";
import { getPlayers, togglePlayerStats, getPlayerStats } from "../../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretRight,
  faCaretDown,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import "./players.css";

class Players extends Component {
  componentDidMount() {
    if (this.props.apiKey && this.props.players.length === 0)
      this.props.getPlayers(this.props.apiKey);
  }

  componentDidUpdate() {
    if (this.props.serverStatus === "ON" && this.props.players.length === 0)
      this.props.getPlayers(this.props.apiKey);
  }

  togglePlayerStats(props, username, expanded) {
    props.togglePlayerStats(props.players, username);
    if (!expanded) this.props.getPlayerStats(this.props.apiKey, username);
  }

  renderPlayer({ Username, active, since, expanded, playtime, firstLogin }) {
    const activeClass = active ? " online" : "";
    const status = active ? ": joined " + since : ": left " + since;
    const color = active ? "#6ead43" : "#d43030"; // green : red
    return (
      <div
        className={"player" + activeClass}
        key={Username}
        onClick={() => this.togglePlayerStats(this.props, Username, expanded)}
      >
        <FontAwesomeIcon
          icon={expanded ? faCaretDown : faCaretRight}
          color={color}
        />
        {expanded ? Username : Username + status}
        <PlayerStats
          expanded={expanded}
          username={Username}
          active={active}
          since={since}
          playtime={playtime}
          firstLogin={firstLogin}
        />
      </div>
    );
  }

  renderPlayers() {
    let playerDivs = [];
    for (let i in this.props.players) {
      playerDivs.push(this.renderPlayer(this.props.players[i]));
    }
    if (playerDivs.length === 0)
      return (
        <div className="col-12">
          <div className="server-status col-12 col-md-5">
            <FontAwesomeIcon icon={faSpinner} pulse />
          </div>
        </div>
      );
    return playerDivs;
  }

  render() {
    const hide = this.props.serverStatus === "ON" ? "" : " d-none";
    return (
      <div className={"players" + hide}>
        <h2>Online</h2>
        <div className="players-list">{this.renderPlayers()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    players: state.players,
    apiKey: state.apiKey,
  };
};

export default connect(mapStateToProps, {
  getPlayers,
  togglePlayerStats,
  getPlayerStats,
})(Players);
