import * as actionNames from "../actions/actionNames";

export default (state = "", action) => {
  switch (action.type) {
    case actionNames.GET_RESOURCE_PACK:
      return action.payload.data;
    default:
      return state;
  }
};
