// FORM MANAGEMENT //
export const HANDLE_FAILED_FORM_SUBMIT = "HANDLE_FAILED_FORM_SUBMIT";
export const HANDLE_FORM_SUBMIT = "HANDLE_FORM_SUBMIT";
export const INITIATE_FORM_SUBMIT = "INITIATE_FORM_SUBMIT";
export const COMPLETE_FORM_SUBMIT = "COMPLETE_FORM_SUBMIT";

// BUTTON MANAGEMENT //
export const INITIATE_BUTTON_CLICK = "INITIATE_BUTTON_CLICK";
export const COMPLETE_BUTTON_CLICK = "COMPLETE_BUTTON_CLICK";

// SERVER MANAGEMENT //
export const TOGGLE_SERVER = "TOGGLE_SERVER";
export const GET_SERVER_STATUS = "GET_SERVER_STATUS";
export const GET_DEV_SERVER_STATUS = "GET_DEV_SERVER_STATUS";
export const GET_BUTTON_MESSAGE = "GET_BUTTON_MESSAGE";
export const GET_USER_SESSION = "GET_USER_SESSION";

// AUTHORIZATION //

// API
export const GET_API_KEY = "GET_API_KEY";

// GENERAL LOGIN
export const INITIAL_USER_SESSION = "INITIAL_USER_SESSION";
export const INITIATE_NEW_PASSWORD_CHALLENGE =
  "INITIATE_NEW_PASSWORD_CHALLENGE";
export const HANDLE_FAILED_LOGIN = "HANDLE_FAILED_LOGIN";
export const HANDLE_SUCCESSFUL_LOGIN = "HANDLE_SUCCESSFUL_LOGIN";
export const HANDLE_SUCCESSFUL_LOGIN_WITH_MESSAGE =
  "HANDLE_SUCCESSFUL_LOGIN_WITH_MESSAGE";
export const SIGN_OUT_USER = "SIGN_OUT_USER";

// CHANGE PASSWORD
export const REDIRECT_TO_CHANGE_PASSWORD = "REDIRECT_TO_CHANGE_PASSWORD";
export const COMPLETE_REDIRECT_TO_CHANGE_PASSWORD =
  "COMPLETE_REDIRECT_TO_CHANGE_PASSWORD";
export const MARK_PASSWORD_CHANGED = "MARK_PASSWORD_CHANGED";
export const UNMARK_PASSWORD_CHANGED = "UNMARK_PASSWORD_CHANGED";
export const MARK_PASSWORDS_DO_NOT_MATCH = "MARK_PASSWORDS_DO_NOT_MATCH";
export const UNMARK_PASSWORDS_DO_NOT_MATCH = "UNMARK_PASSWORDS_DO_NOT_MATCH";
export const CLEAR_LOGIN_ERROR_MESSAGE = "CLEAR_LOGIN_ERROR_MESSAGE";

// REGISTER //
export const REDIRECT_TO_REGISTRATION_FORM = "REDIRECT_TO_REGISTRATION_FORM";
export const COMPLETE_REDIRECT_TO_REGISTRATION_FORM =
  "COMPLETE_REDIRECT_TO_REGISTRATION_FORM";
export const NEW_PLAYER_REQUESTS = "NEW_PLAYER_REQUESTS";
export const TOGGLE_APPROVAL = "TOGGLE_APPROVAL";
export const MARK_EMAILS_DO_NOT_MATCH = "MARK_EMAILS_DO_NOT_MATCH";
export const UNMARK_EMAILS_DO_NOT_MATCH = "UNMARK_EMAILS_DO_NOT_MATCH";
export const HANDLE_REGISTRATION_FAIL = "HANDLE_REGISTRATION_FAIL";
export const HANDLE_REGISTRATION_SUCCESS = "HANDLE_REGISTRATION_SUCCESS";
export const CLEAR_REGISTRATION_RESULT = "CLEAR_REGISTRATION_RESULT";

// FORGOT PASSWORD //
export const REDIRECT_TO_FORGOT_PASSWORD_FORM =
  "REDIRECT_TO_FORGOT_PASSWORD_FORM";
export const COMPLETE_REDIRECT_TO_FORGOT_PASSWORD_FORM =
  "COMPLETE_REDIRECT_TO_REGISTRATION_FORM";
export const HANDLE_FORGOT_PASSWORD_FAIL = "HANDLE_FORGOT_PASSWORD_FAIL";
export const HANDLE_FORGOT_PASSWORD_SUCCESS = "HANDLE_FORGOT_PASSWORD_SUCCESS";
export const CLEAR_FORGOT_PASSWORD_RESULT = "CLEAR_FORGOT_PASSWORD_RESULT";

// WIDGETS //

// TIMER
export const GET_STOP_TIME = "GET_STOP_TIME";
export const DECREASE_TIMER = "DECREASE_TIMER";
export const INCREASE_TIMER = "INCREASE_TIMER";
export const UPDATE_TIMER = "UPDATE_TIMER";

// PLAYERS
export const GET_PLAYERS = "GET_PLAYERS";
export const GET_PLAYER_STATS = "GET_PLAYER_STATS";
export const TOGGLE_PLAYER_STATS = "TOGGLE_PLAYER_STATS";

// RESOURCE PACK
export const GET_RESOURCE_PACK = "GET_RESOURCE_PACK";

// MAP //
export const GET_WINDOW_HEIGHT = "GET_WINDOW_HEIGHT";
