import { faGlassMartiniAlt } from "@fortawesome/free-solid-svg-icons";

export const dev = {
  devMode: false,
  devModeLight: false,
  requiresLogin: false,
  serverStatus: "ON",
  stopTime: Date.now() / 1000 + 7160,
  buttonMessage: "STOP",
  texturePack: {
    name: "Sphax PureBDCraft 64x",
    url: "https://bdcraft.net/downloads/purebdcraft-minecraft/",
  },
  players: [
    {
      Username: "wooster2011",
      active: true,
      since: "1 day ago",
      expanded: false,
    },
    {
      Username: "eurostud",
      active: false,
      since: "1 week ago",
      expanded: false,
    },
  ],
  session: {
    email: "daniel.jm.gimmler@gmail.com",
    requiresLogin: false,
    session: {},
  },
  apiUrl: "http://127.0.0.1:3000",
};

export const config = {
  apiId: "16z7hps25k",
  apiVersion: "v1",
  userPoolId: "us-west-2_qJHXYvC2N",
  clientId: "77eto42l3pnvjbn12vt6c1sa5j",
  domain:
    dev.devMode || dev.devModeLight ? "localhost" : "happylandingsserver.com",
  secure: dev.devMode || dev.devModeLight ? false : true,
  region: "us-west-2",
  cookieExpiration: 1,
  cookiePath: "/",
  mandatorySignIn: true,

  // server info
  minecraftVersion: "1.19.2",
  serverIp: "35.167.114.167",
  texturePack: {
    name: "Sphax PureBDCraft 64x",
    url: "https://bdcraft.net/downloads/purebdcraft-minecraft/",
  },
};
