import React, { Component } from "react";
import { connect } from "react-redux";
import "./PlayerStats.css";

class PlayerStats extends Component {
  render() {
    return (
      <div
        className={
          this.props.expanded
            ? "PlayerStats card-body"
            : "PlayerStats card-body d-none"
        }
      >
        <table className="tabe">
          <tbody>
            <tr className="player-stat">
              <td>
                <strong>{this.props.active ? "Joined:" : "Left:"}</strong>
              </td>
              <td>{this.props.since}</td>
            </tr>
            <tr className="player-stat">
              <td>
                <strong>Playing Since:</strong>
              </td>
              <td>{this.props.firstLogin || "01/01/1970"}</td>
            </tr>
            <tr className="player-stat">
              <td>
                <strong>Total Playtime:</strong>
              </td>
              <td>{this.props.playtime || "00:00:00"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(PlayerStats);
