import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Redirect } from "react-router-dom";
import { Form } from "../Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  completeRedirectToChangePassword,
  handleFailedFormSubmit,
  initiateFormSubmit,
  completeFormSubmit,
  markPasswordsDoNotMatch,
  unmarkPasswordsDoNotMatch,
  markEmailsDoNotMatch,
  unmarkEmailsDoNotMatch,
  completeRedirectToRegistrationForm,
  requestForgotPassword,
  clearForgotPasswordResult,
  confirmForgotPassword,
} from "../../actions";
import "./ForgotPassword.css";

class ForgotPassword extends Form {
  nevermind(props) {
    this.clearMessage(this.props);
    props.clearForgotPasswordResult({
      submitted: false,
      submittedWithCode: false,
    });
    props.completeRedirectToRegistrationForm();
  }

  validateForm(formValues, props) {
    let result = true;

    if (!formValues.email) return false;

    if (
      props.forgotPasswordResult.submitted &&
      (!formValues.password || !formValues.passwordconfirm || !formValues.code)
    )
      return false;

    // validate emails
    let re = new RegExp("^[\\w\\.\\d\\-_\\+]+@[\\w\\d\\-_\\+]+\\.[\\w]+$");
    if (!formValues.email) {
      result = false;
    } else if (!re.test(formValues.email)) {
      props.markEmailsDoNotMatch();
      result = false;
    } else {
      props.unmarkEmailsDoNotMatch();
    }

    // validate passwords (cognito handles password complexity checks)
    if (formValues.password !== formValues.passwordconfirm) {
      props.markPasswordsDoNotMatch();
      result = false;
    } else {
      props.unmarkPasswordsDoNotMatch();
    }

    return result;
  }

  handleFailedSubmit({ handleFailedFormSubmit, completeFormSubmit }) {
    handleFailedFormSubmit();
    completeFormSubmit();
  }

  handleOnSubmit(formValues, props) {
    if (this.validateForm(formValues, props)) {
      // indicate form submit is in progress
      props.initiateFormSubmit();
      if (!formValues.code) props.requestForgotPassword(formValues);
      else props.confirmForgotPassword(formValues);
    } else {
      this.handleFailedSubmit(this.props);
    }
  }

  clearMessage = (props) => {
    // if (props.forgotPasswordResult.submitted && !props.forgotPasswordResult.failedForgotPassword)
    //   props.completeFormSubmit()

    // clear either password mismatch message or login failure message
    props.unmarkEmailsDoNotMatch();
    props.unmarkPasswordsDoNotMatch();
    props.clearForgotPasswordResult({
      submitted: this.props.forgotPasswordResult.submitted,
      submittedWithCode: this.props.forgotPasswordResult.submittedWithCode,
    });
  };

  getFields = (forgotPasswordResult) => {
    let fields = [
      <Field
        name="email"
        component={this.renderInput}
        label="Email"
        type="text"
        placeholder="myemail@domain.com"
        key="email"
      />,
    ];

    if (forgotPasswordResult.submitted) {
      fields.push(
        <Field
          name="code"
          component={this.renderInput}
          label="Code"
          type="number"
          placeholder="Enter the 6-digit code you received in the email"
          key="code"
        />
      );
      fields.push(
        <Field
          name="password"
          component={this.renderInput}
          label="New Password"
          type="password"
          placeholder="enter new password"
          key="password"
        />
      );
      fields.push(
        <Field
          name="passwordconfirm"
          component={this.renderInput}
          label="New Password (Confirm)"
          type="password"
          placeholder="re-enter new password"
          key="passwordconfirm"
        />
      );
    }

    return fields;
  };

  renderMessage(props) {
    props.completeFormSubmit();
    const closeMsgBtn = (
      <div
        className="btn close-message"
        onClick={() => {
          this.clearMessage(props);
        }}
      >
        x
      </div>
    );

    // handle forgot password results
    if (props.forgotPasswordResult.msg !== "") {
      let alertType = props.forgotPasswordResult.failedForgotPassword
        ? "danger"
        : "success";

      return (
        <div className={"msg alert alert-" + alertType}>
          <div className="header">{props.forgotPasswordResult.msg}</div>
          {closeMsgBtn}
        </div>
      );
    }

    // handle submit failures
    else if (props.emailsDoNotMatch || props.passwordsDoNotMatch) {
      props.completeFormSubmit();
      let msg = props.emailsDoNotMatch
        ? "Please enter valid email"
        : "Passwords don't match";
      return (
        <div className="msg alert alert-danger">
          <div className="header">{msg}</div>
          {closeMsgBtn}
        </div>
      );
    } else if (props.passwordsDoNotMatch) {
      props.completeFormSubmit();
      return (
        <div className="msg alert alert-danger">
          <div className="header">Passwords do not matach</div>
          {closeMsgBtn}
        </div>
      );
    } else {
      return "";
    }
  }

  renderButtonMessage({ formSubmitInProgress, forgotPasswordResult }) {
    if (formSubmitInProgress) {
      return (
        <span>
          <FontAwesomeIcon icon={faSpinner} spin />
        </span>
      );
    } else if (!forgotPasswordResult.submitted) {
      return <span>Send Code</span>;
    } else {
      return <span>Submit</span>;
    }
  }

  render() {
    let returnMsg =
      this.props.forgotPasswordResult.failedForgotPassword === false &&
      this.props.forgotPasswordResult.submittedWithCode === true
        ? "Return to login"
        : "Nevermind";

    // handle page display or redirect
    if (!this.props.goToForgotPasswordForm) {
      // If refreshing page or opening new session on this page, redirect home
      // if not logged in
      return (
        <div className="backdrop">
          <div className="overlay">
            <div>
              <Redirect to="/" />
            </div>
          </div>
        </div>
      );
    } else {
      // DEFAULT: Display form
      return (
        <div className="backdrop">
          <div className="blur">
            <div className="overlay"></div>
          </div>
          <div className="overlay">
            <div className={"ForgotPassword " + this.containerClass}>
              <h1>Oops.</h1>
              <form
                className={this.formClass}
                onSubmit={this.props.handleSubmit((formValues) => {
                  this.handleOnSubmit(formValues, this.props);
                })}
              >
                {this.getFields(this.props.forgotPasswordResult)}
                {this.renderMessage(this.props)}
                <button
                  className={this.btnClass}
                  disabled={
                    this.props.formSubmitInProgress ||
                    this.props.forgotPasswordResult.submittedWithCode
                  }
                >
                  {this.renderButtonMessage(this.props)}
                </button>
              </form>
              <button
                className="nevermind"
                disabled={this.props.formSubmitInProgress}
                onClick={() => this.nevermind(this.props)}
              >
                <span>{returnMsg}</span>
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    formSubmitInProgress: state.formSubmitInProgress,
    failedFormSubmit: state.failedFormSubmit,
    requiresLogin: state.session.requiresLogin,
    email: state.session.email,
    session: state.session.session,
    failedLogin: state.loginResult.failedLogin,
    failedLoginMsg: state.loginResult.msg,
    redirectToChangePassword: state.redirectToChangePassword,
    passwordsDoNotMatch: state.passwordsDoNotMatch,
    emailsDoNotMatch: state.emailsDoNotMatch,
    goToForgotPasswordForm: state.goToForgotPasswordForm,
    forgotPasswordResult: state.forgotPasswordResult,
  };
};

export default connect(mapStateToProps, {
  completeRedirectToChangePassword,
  handleFailedFormSubmit,
  initiateFormSubmit,
  completeFormSubmit,
  markPasswordsDoNotMatch,
  unmarkPasswordsDoNotMatch,
  markEmailsDoNotMatch,
  unmarkEmailsDoNotMatch,
  completeRedirectToRegistrationForm,
  requestForgotPassword,
  clearForgotPasswordResult,
  confirmForgotPassword,
})(reduxForm({ form: "login", touchOnBlur: false })(ForgotPassword));
