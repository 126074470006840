import * as action from "./actionNames";
import minecraftServer from "../apis/minecraftServer";
import userPool from "../apis/userPool";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { dev } from "../config";

/////////////////////
// ACTION CREAATORS //
/////////////////////

/////////////////////
// FORM MANAGEMENT //

export const handleFormSubmit = () => (dispatch) => {
  // first resetting login success removes error message from login form and
  // allows login button to show loading icon
  dispatch({
    type: action.HANDLE_SUCCESSFUL_LOGIN,
    payload: undefined,
  });
  dispatch({
    type: action.HANDLE_FORM_SUBMIT,
    payload: true,
  });
};

export const handleFailedFormSubmit = () => {
  return {
    type: action.HANDLE_FAILED_FORM_SUBMIT,
    payload: true,
  };
};

export const initiateFormSubmit = () => {
  return {
    type: action.INITIATE_FORM_SUBMIT,
    payload: undefined,
  };
};

export const completeFormSubmit = () => {
  return {
    type: action.COMPLETE_FORM_SUBMIT,
    payload: undefined,
  };
};

///////////////////////
// BUTTON MANAGEMENT //
export const initiateButtonClick = () => {
  return {
    type: action.INITIATE_BUTTON_CLICK,
    payload: undefined,
  };
};

export const completeButtonClick = () => {
  return {
    type: action.COMPLETE_BUTTON_CLICK,
    payload: undefined,
  };
};

///////////////////////
// SERVER MANAGEMENT //
export const toggleServer = (toggleStatus, session) => async (dispatch) => {
  // does nothing if status is neither on nor off
  if (toggleStatus === "OFF" || toggleStatus === "ON") {
    let endpoint = "/stop";
    if (toggleStatus === "OFF") endpoint = "/start";
    // Authorization header must be added here as we could not get the token on
    // initialization
    minecraftServer.defaults.headers.Authorization = session
      .getIdToken()
      .getJwtToken();
    if (endpoint === "/stop")
      minecraftServer
        .post("/logoutUsers")
        .then((response) => {})
        .catch((err) => {
          console.error("toggleServer", err);
        });
    minecraftServer
      .post(endpoint)
      .then((response) => {
        dispatch({
          type: action.TOGGLE_SERVER,
          payload: response,
        });
        setTimeout(() => {
          dispatch(getServerStatus(session));
        }, 3 * 1000); // sleep 3 seconds
      })
      .catch((err) => {
        console.error("toggleServer", err);
        setTimeout(() => {
          dispatch(getServerStatus(session));
          dispatch(completeButtonClick());
        }, 3 * 1000); // sleep 3 seconds
      });
  }
};

export const getServerStatus = (session) => async (dispatch) => {
  // Authorization header must be added here as we could not get the token on
  // initialization
  if (dev.devMode)
    return {
      type: action.GET_DEV_SERVER_STATUS,
      payload: {},
    };
  minecraftServer.defaults.headers.Authorization = session
    .getIdToken()
    .getJwtToken();
  minecraftServer.interceptors.request.use(function (config) {
    config.headers.Authorization = session.getIdToken().getJwtToken();
    return config;
  });
  minecraftServer
    .get("/status")
    .then((response) => {
      dispatch({
        type: action.GET_SERVER_STATUS,
        payload: response,
      });
      dispatch(getButtonMessage(response.data)); // also update button message

      // sleep only 10 seconds if server is in the process of starting or stopping
      // else wait 5 min before checking statuus again
      let sleepSeconds = !(
        response.data === "stopped" || response.data === "running"
      )
        ? 10
        : 300;
      setTimeout(() => {
        dispatch(getServerStatus(session));
      }, sleepSeconds * 1000);
    })
    .catch((err) => {
      console.error("getServerStatus", err);
    });
};

export const serverStatusUnknown = () => {
  return {
    type: action.GET_SERVER_STATUS,
    payload: { data: undefined },
  };
};

export const getButtonMessage = (status) => {
  return {
    type: action.GET_BUTTON_MESSAGE,
    payload: status,
  };
};

////////////////////
// AUTHENTICATION //
export const getApiKey = (session) => async (dispatch) => {
  minecraftServer.defaults.headers.Authorization = session
    .getIdToken()
    .getJwtToken();
  minecraftServer
    .get("/getKey")
    .then((response) => {
      dispatch({
        type: action.GET_API_KEY,
        payload: response.data,
      });
      setTimeout(() => {
        dispatch(getResourcePack(response.data));
        dispatch(getPlayers(response.data));
      }, 3 * 1000); // sleep 3 seconds
    })
    .catch((err) => {
      console.error("getApiKey", err);
    });
};

export const getUserSession = () => (dispatch) => {
  // Called on page load or refresh
  //
  // get email and refresh token from cookies to pull up previous session if
  // available
  const email = userPool.storage.getItem("userEmail");
  const refreshToken = userPool.storage.getItem("refreshToken");
  if (!email || !refreshToken) {
    dispatch({
      type: action.GET_USER_SESSION,
      payload: undefined,
    });
  }
  // attempt to pull up previous session
  try {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    const RefreshToken = new CognitoRefreshToken({
      RefreshToken: refreshToken,
    });
    user.refreshSession(RefreshToken, (err, session) => {
      if (err) {
        dispatch({
          type: action.GET_USER_SESSION,
          payload: undefined,
        });
      }

      if (session) {
        // session pulled up successfully!
        dispatch({
          type: action.GET_USER_SESSION,
          payload: { session: session, email: email },
        });
        dispatch(getApiKey(session)); // retrieve api key
      }
    });
  } catch (err) {
    dispatch({
      type: action.GET_USER_SESSION,
      payload: undefined,
    });
  }

  // refresh token every 2 hours
  setTimeout(() => {
    dispatch(getUserSession());
  }, 7200 * 1000); // sleep 2 hours
};

export const handleLogin =
  (formValues, updatePassword = false) =>
  (dispatch) => {
    const user = new CognitoUser({
      Username: formValues.email,
      Pool: userPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: formValues.email,
      Password: formValues.password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        // save email and refresh token to cookies
        userPool.storage.setItem(
          "refreshToken",
          String(data.refreshToken.token)
        );
        userPool.storage.setItem("userEmail", formValues.email);

        if (!updatePassword) {
          // initial user session and navigate to control hub

          dispatch({
            type: action.INITIAL_USER_SESSION,
            payload: { session: data, email: formValues.email },
          });

          // indicate login successful
          dispatch({
            type: action.HANDLE_SUCCESSFUL_LOGIN,
            payload: undefined,
          });
          dispatch(getUserSession());
          // if (data.session) dispatch(getApiKey(data.session)); // retrieve api key
        } else {
          dispatch(setNewPassword(formValues, user));
        }
      },

      onFailure: (err) => {
        dispatch({
          type: action.HANDLE_FAILED_LOGIN,
          payload: err,
        });
      },

      newPasswordRequired: (data) => {
        if (!formValues.newpassword) {
          // redirect to change password page
          dispatch(redirectToChangePassword());
          dispatch({
            type: action.INITIATE_NEW_PASSWORD_CHALLENGE,
            payload: { session: data, email: formValues.email },
          });
        } else {
          // submit completed new password form
          delete data.email_verified;
          delete data.email;
          user.completeNewPasswordChallenge(
            String(formValues.newpassword),
            data,
            {
              onSuccess: (data) => {
                userPool.storage.setItem(
                  "refreshToken",
                  String(data.refreshToken.token)
                );
                userPool.storage.setItem("userEmail", formValues.email);
                dispatch({
                  type: action.INITIAL_USER_SESSION,
                  payload: { session: data, email: formValues.email },
                });
                dispatch({
                  type: action.MARK_PASSWORD_CHANGED,
                  payload: undefined,
                });
              },
              onFailure: (err) => {
                dispatch({
                  type: action.HANDLE_FAILED_LOGIN,
                  payload: err,
                });
              },
            }
          );
        }
      },
    });
  };

export const signOut = (email) => {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });
  user.signOut();
  return {
    type: action.SIGN_OUT_USER,
    payload: undefined,
  };
};

export const setNewPassword = (formValues, user) => (dispatch) => {
  user.changePassword(
    formValues.password,
    formValues.newpassword,
    (err, success) => {
      if (err) {
        dispatch({
          type: action.HANDLE_FAILED_LOGIN,
          payload: err,
        });
      } else if (success) {
        dispatch({
          type: action.HANDLE_SUCCESSFUL_LOGIN,
          payload: undefined,
        });
        dispatch({
          type: action.MARK_PASSWORD_CHANGED,
          payload: undefined,
        });
      }
    }
  );
};

export const clearLoginFailureMessage = () => {
  return {
    type: action.CLEAR_LOGIN_ERROR_MESSAGE,
    payload: undefined,
  };
};

export const unmarkPasswordChanged = () => {
  return {
    type: action.UNMARK_PASSWORD_CHANGED,
    payload: undefined,
  };
};

export const redirectToChangePassword = () => {
  return {
    type: action.REDIRECT_TO_CHANGE_PASSWORD,
    payload: undefined,
  };
};

export const completeRedirectToChangePassword = () => {
  return {
    type: action.COMPLETE_REDIRECT_TO_CHANGE_PASSWORD,
    payload: undefined,
  };
};

export const markPasswordsDoNotMatch = () => {
  return {
    type: action.MARK_PASSWORDS_DO_NOT_MATCH,
    payload: undefined,
  };
};

export const unmarkPasswordsDoNotMatch = () => {
  return {
    type: action.UNMARK_PASSWORDS_DO_NOT_MATCH,
    payload: undefined,
  };
};

// REGISTRATION //

export const markEmailsDoNotMatch = () => {
  return {
    type: action.MARK_EMAILS_DO_NOT_MATCH,
    payload: undefined,
  };
};

export const unmarkEmailsDoNotMatch = () => {
  return {
    type: action.UNMARK_EMAILS_DO_NOT_MATCH,
    payload: undefined,
  };
};

export const redirectToRegistrationForm = () => {
  return {
    type: action.REDIRECT_TO_REGISTRATION_FORM,
    payload: undefined,
  };
};

export const completeRedirectToRegistrationForm = () => {
  return {
    type: action.COMPLETE_REDIRECT_TO_REGISTRATION_FORM,
    payload: undefined,
  };
};

export const getNewPlayers = (session) => async (dispatch) => {
  dispatch({
    type: action.NEW_PLAYER_REQUESTS,
    payload: [], // shoot out already so request isn't made twice
  });

  // Authorization header must be added here as we could not get the token on
  // initialization
  minecraftServer.defaults.headers.Authorization = session
    .getIdToken()
    .getJwtToken();
  minecraftServer
    .get("/getNewUsers")
    .then((response) => {
      dispatch({
        type: action.NEW_PLAYER_REQUESTS,
        payload: response,
      });
    })
    .catch((err) => {});
};

export const loadNewPlayers = () => {
  return {
    // show loading spinner
    type: action.NEW_PLAYER_REQUESTS,
    payload: { data: undefined },
  };
};

export const togglePlayerApproval =
  (session, email, approval) => async (dispatch) => {
    // Authorization header must be added here as we could not get the token on
    // initialization
    dispatch({
      type: action.INITIATE_FORM_SUBMIT,
      payload: undefined,
    });
    minecraftServer.defaults.headers.Authorization = session
      .getIdToken()
      .getJwtToken();
    dispatch({
      type: action.TOGGLE_APPROVAL,
      payload: undefined,
    });
    minecraftServer
      .post(
        "/putUserStatus",
        JSON.stringify({
          User: email,
          Status: approval,
        })
      )
      .then((response) => {
        dispatch({
          type: action.COMPLETE_FORM_SUBMIT,
          payload: undefined,
        });
        dispatch({
          type: action.TOGGLE_APPROVAL,
          payload: undefined,
        });
        let msg =
          "Successfully added " +
          email +
          " to the website. They should now be able to log in with the " +
          "password they set in their registration request";
        if (approval === "reject")
          msg =
            email +
            " has been rejected access and will not receive a login. If this" +
            " was a mistake, reach out to Daniel to fix.";
        dispatch({
          type: action.HANDLE_SUCCESSFUL_LOGIN_WITH_MESSAGE,
          payload: { message: msg },
        });
      })
      .catch((err) => {
        dispatch({
          type: action.HANDLE_FAILED_LOGIN,
          payload: {
            message:
              "Something went wrong in sending approval, try again, or ask Daniel if it still doesn't work.",
          },
        });
      });
  };

export const registerNewUser = (formValues) => async (dispatch) => {
  const attributes = [
    new CognitoUserAttribute({ Name: "name", Value: formValues.email }),
    new CognitoUserAttribute({ Name: "email", Value: formValues.email }),
  ];
  userPool.signUp(
    formValues.email,
    formValues.password,
    attributes,
    null,
    (err, result) => {
      if (err)
        dispatch({
          type: action.HANDLE_REGISTRATION_FAIL,
          payload: err,
        });
      else
        dispatch({
          type: action.HANDLE_REGISTRATION_SUCCESS,
          payload: result.user.getUsername(),
        });
    }
  );
};

export const clearRegistrationResult = () => {
  return {
    type: action.CLEAR_REGISTRATION_RESULT,
    payload: undefined,
  };
};

// FORGOT PASSWORD //
export const redirectToForgotPasswordForm = () => {
  return {
    type: action.REDIRECT_TO_FORGOT_PASSWORD_FORM,
    payload: undefined,
  };
};

export const requestForgotPassword =
  ({ email }) =>
  (dispatch) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    user.forgotPassword({
      onSuccess: (data) => {
        dispatch({
          type: action.HANDLE_FORGOT_PASSWORD_SUCCESS,
          payload: { withCode: false },
        });
      },
      onFailure: (err) => {
        console.error(err);
        dispatch({
          type: action.HANDLE_FORGOT_PASSWORD_FAIL,
          payload: { withCode: false, err: err },
        });
      },
    });
  };

export const confirmForgotPassword = (formValues) => (dispatch) => {
  const user = new CognitoUser({
    Username: formValues.email,
    Pool: userPool,
  });
  user.confirmPassword(formValues.code, formValues.password, {
    onSuccess: (data) => {
      dispatch({
        type: action.HANDLE_FORGOT_PASSWORD_SUCCESS,
        payload: { withCode: true },
      });
    },
    onFailure: (err) => {
      console.error(err);
      dispatch({
        type: action.HANDLE_FORGOT_PASSWORD_FAIL,
        payload: { withCode: true, err: err },
      });
    },
  });
};

export const clearForgotPasswordResult = (forgotPasswordResult) => {
  return {
    type: action.CLEAR_FORGOT_PASSWORD_RESULT,
    payload: forgotPasswordResult,
  };
};

/////////////
// WIDGETS //

// TIMER //
export const getStopTime = (session) => async (dispatch) => {
  if (dev.devMode)
    dispatch({
      type: action.GET_STOP_TIME,
      payload: dev.stopTime,
    });
  else {
    minecraftServer.defaults.headers.Authorization = session
      .getIdToken()
      .getJwtToken();
    minecraftServer
      .get("/timer")
      .then((response) => {
        dispatch({
          type: action.GET_STOP_TIME,
          payload: response.data,
        });
        dispatch(startTimer());
      })
      .catch((err) => {
        console.error("getStopTime", err);
      });
  }
};

export const startTimer = () => async (dispatch) => {
  await new Promise((r) => setTimeout(r, 1 * 1000)); // sleep 1 seconds
  dispatch(decreaseTimer());
  dispatch(startTimer());
};

export const increaseTimer =
  (session, stopTime, currentTime) => async (dispatch) => {
    dispatch({
      type: action.UPDATE_TIMER,
      payload: undefined,
    });

    // increment but 30 min or up to 2 hour max
    const overflow = parseInt(currentTime) + 1800 - 7200; // > 0 = > 2 hours
    const increment = overflow > 0 ? 1800 - overflow - 60 : 1800; // if > 2 hours, also take off 1 min buffer
    const body = {
      value: stopTime + increment + "", // add 30 min and convert to string
    };
    minecraftServer.defaults.headers.Authorization = session
      .getIdToken()
      .getJwtToken();
    minecraftServer
      .post("/updateTimer", JSON.stringify(body))
      .then((response) => {
        dispatch({
          type: action.UPDATE_TIMER,
          payload: undefined,
        });
        if (response.status === 200)
          dispatch({
            type: action.INCREASE_TIMER,
            payload: undefined,
          });
      })
      .catch((err) => {
        console.error("increaseTimer", err);
      });
  };

export const decreaseTimer = () => {
  return {
    type: action.DECREASE_TIMER,
    payload: undefined,
  };
};

// PLAYERS //
export const getPlayers = (token) => async (dispatch) => {
  if (dev.devMode)
    dispatch({
      type: action.GET_PLAYERS,
      response: dev.players,
    });
  else {
    minecraftServer.defaults.headers["x-api-key"] = token;
    minecraftServer
      .post("/getLogins")
      .then((response) => {
        dispatch({
          type: action.GET_PLAYERS,
          payload: response,
        });

        setTimeout(() => {
          dispatch(getPlayers(token));
        }, 300 * 1000); // sleep 5 minutes
      })
      .catch((err) => {
        console.error("getPlayers", err);
      });
  }
};

export const getPlayerStats = (token, username) => async (dispatch) => {
  minecraftServer.defaults.headers["x-api-key"] = token;
  minecraftServer
    .post("/getLogins", { Usernames: [username] })
    .then((response) => {
      dispatch({
        type: action.GET_PLAYER_STATS,
        payload: response,
      });
    })
    .catch((err) => {
      console.error("getPlayerStats", err);
    });
};

export const togglePlayerStats = (players, username) => {
  for (let i = 0; i < players.length; i++) {
    if (players[i].Username === username)
      return {
        type: action.TOGGLE_PLAYER_STATS,
        payload: players[i],
      };
  }
  return {
    type: action.TOGGLE_PLAYER_STATS,
    payload: undefined,
  };
};

// RESOURCE PACK
export const getResourcePack = (token) => async (dispatch) => {
  if (dev.devMode)
    dispatch({
      type: action.GET_RESOURCE_PACK,
      response: "",
    });
  else {
    // get resource pack URL from cookie if available
    let resourcePack = userPool.storage.getItem("resourcePack");
    if (resourcePack) {
      try {
        // need to do local handling of expiration, as presigned url expiration
        // is short than user pool expiration
        let resourcePackArr = resourcePack.split("=");
        let expires = parseInt(resourcePackArr[resourcePackArr.length - 1]);
        let now = parseInt(Date.now() / 1000);
        if (expires >= now)
          dispatch({
            type: action.GET_RESOURCE_PACK,
            payload: { data: resourcePack },
          });
        else resourcePack = undefined;
      } catch (err) {
        console.error("getResourcePack", err);
        resourcePack = undefined;
      }
    }

    if (!resourcePack) {
      dispatch({
        type: action.GET_RESOURCE_PACK,
        payload: { data: "retrieving" },
      });
      minecraftServer.defaults.headers["x-api-key"] = token;
      minecraftServer
        .get("/getResourcePack")
        .then((response) => {
          dispatch({
            type: action.GET_RESOURCE_PACK,
            payload: response,
          });
          userPool.storage.setItem("resourcePack", response.data);
        })
        .catch((err) => {});
    }
  }
};

// MAP //
export const getWindowHeight = (window) => {
  return {
    type: action.GET_WINDOW_HEIGHT,
    payload: window.document.body.scrollHeight + "px",
  };
};
