import * as actionNames from "../actions/actionNames";
// import { dev, config } from "../config";

export default (
  state = {
    newPlayerRequests: undefined,
    approvalInProgress: false,
  },
  action
) => {
  switch (action.type) {
    case actionNames.NEW_PLAYER_REQUESTS:
      return {
        ...state,
        newPlayerRequests: action.payload.data || [],
      };
    case actionNames.TOGGLE_APPROVAL:
      return {
        ...state,
        approvalInProgress: !state.approvalInProgress,
      };
    default:
      return state;
  }
};
