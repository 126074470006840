import * as actionNames from "../actions/actionNames";

export default (
  state = { failedRegistration: false, msg: "", submitted: false },
  action
) => {
  switch (action.type) {
    case actionNames.HANDLE_REGISTRATION_FAIL:
      console.log("action", action);
      return {
        failedRegistration: true,
        msg: action.payload.message,
        submitted: true,
      };
    case actionNames.HANDLE_REGISTRATION_SUCCESS:
      let msg =
        "Successfully registered user " +
        action.payload +
        " pending approval. Once approved, you will be able to log in with this email and the given password.";
      return {
        failedRegistration: false,
        msg: msg,
        submitted: true,
      };
    case actionNames.CLEAR_REGISTRATION_RESULT:
      return { failedRegistration: false, msg: "", submitted: false };
    default:
      return state;
  }
};
