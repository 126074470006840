import * as actionNames from "../actions/actionNames";
import { dev } from "../config";

export default (
  state = {
    session: {},
    requiresLogin: dev.devMode ? dev.requiresLogin : undefined,
    email: undefined,
  },
  action
) => {
  switch (action.type) {
    case actionNames.GET_USER_SESSION:
      if (dev.devMode) return dev.session;
      if (action.payload) {
        return {
          session: action.payload.session,
          requiresLogin: false,
          email: action.payload.email,
        };
      } 
      console.log("Unable to pull up previous session, login required...");
      return { session: {}, requiresLogin: true };
    case actionNames.INITIAL_USER_SESSION:
      if (dev.devMode) return dev.session;
      if (action.payload) {
        return {
          session: action.payload.session,
          requiresLogin: false,
          email: action.payload.email,
        };
      }
      return state;
    case actionNames.SIGN_OUT_USER:
      if (dev.devMode) return dev.session;
      return {
        session: undefined,
        requiresLogin: true,
        email: undefined,
      };
    default:
      return state;
  }
};
